import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/SEO"

export default () => {
  return (
    <Layout>
      <Seo />
      <h1>The Venue</h1>
      <p>
        We'll be holding the ceremony and reception at Wren's Roost Event Barn in Naples, NY.  You can find some general information about the venu below but if you want to get the full scoop, you can check out their website <a href="https://www.wrensroostbarn.com/" target="_blank">here</a>.</p>

      <h2>Directions</h2>
      <p>6995 Longsdorf Road, Naples, NY 14512</p>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2935.440249366815!2d-77.41224998453507!3d42.63082597916972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d10fd9a7a37d11%3A0x8e0cf3cfec0a2c17!2s6995%20Longsdorf%20Rd%2C%20Naples%2C%20NY%2014512!5e0!3m2!1sen!2sus!4v1577913431772!5m2!1sen!2sus" width="100%" height="280" frameborder="0" marginheight="0" allowfullscreen=""></iframe>
      <h2>Parking</h2>
      <p>There is ample parking in the field right where you pull in.  (Please be careful not to park on/near the septic system).</p>
      <h2>Things to Keep in Mind</h2>
      <p>To keep things running as smoothly as possible, here are a few guidelines to keep in mind while we celebrate:</p>
      <ul className="chevron-list">
        <li>For covid-related stuff, please reference the info outlined <Link to="/covid">here.</Link></li>
        <li>Weather permitting, the ceremony and most of the activities will take place outdoors.  Please keep this in mind when dressing for the occasion.</li>
        <li>Please don't throw rocks into the pond.  We know - we were tempted, too.  But the rocks around the pond are decorative and it cost money to put them where they are.</li>
        <li>Please keep smoking and cigarette butts in the designated smoking area (to the right of the barn when you walk in there is a seating area for you). The building and grounds are otherwise smoke-free.</li>
        <li>During the ceremony, please keep the center aisle clear for our photographer. We really like their work and want to give them what they need to do their best.</li>
      </ul>
      <p>Thanks for your help!</p>
    </Layout>
  )
}

